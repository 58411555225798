module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Bootstrap Template","short_name":"gatsby-bootstrap-template","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"content/images/logo_small.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3af4305fdd563ae7368c0c3d8e2a1a34"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PYK2S0SJYW"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
